import translateType from "../../hooks/translateType";

const Icon = (props) => {

    const {type, classes} = props;

  return (
    <img src={`/icons/${type}.svg`} alt={translateType(type)} className={classes}/>
  )
}

export default Icon