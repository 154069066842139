import { useEffect, useRef, useState } from "react";
import ContentSpotify from "./types/ContentSpotify";
import ContentTitle from "./types/ContentTitle";
import ContentArtwork from "./types/ContentArtwork";
import ContentInfo from "./types/ContentInfo";
import ContentYoutube from "./types/ContentYoutube";

import Connections from "./connections/Connections";
import ContentExternalLinks from "./types/ContentExternalLinks";

import History from "../Interface/History";

const ContentWrapper = (props) => {
  const {
    entries,
    selectedEntry,
    setSelectedEntry,
    spotifyIsActive,
    setSpotifyIsActive,
    spotifyUrl,
    setSpotifyUrl,
    historyIndex,
    historyIds,
    visibleEntries,
    setVisibleEntries
  } = props;

  const [contentEntry, setContentEntry] = useState(null);
  const [embedUrl, setEmbedUrl] = useState("");

  const contentWrapperRef = useRef();

  const returnToTop = () => {
    if (contentWrapperRef.current) {
      contentWrapperRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (selectedEntry !== "") {
      const filteredEntries = entries.filter((entry) => {
        return entry.id === selectedEntry;
      });
      returnToTop();
      setContentEntry(filteredEntries[0]);
    }
  }, [selectedEntry]);

  const handleClose = () => {
    setSelectedEntry("");
    setEmbedUrl("");
  };

  return (
    <div className={`content__container ${selectedEntry ? "active" : ""} ${spotifyIsActive ? "spotify-open" : ""}`}>
      <button
        className="content__close-button close-button container"
        onClick={handleClose}
      ></button>
      {contentEntry ? (
        <>
          <section className="content__title-wrapper container">
            <ContentTitle
              {...{
                contentEntry,
              }}
            />
          </section>
          <History
        {...{
          entries,
          historyIndex,
          historyIds,
          selectedEntry,
          setSelectedEntry,
          visibleEntries,
          setVisibleEntries
        }}
      />
          <section className="content__content-outer-wrapper container">
            <div
              className="content__content-inner-wrapper"
              ref={contentWrapperRef}
            >
              {contentEntry.youtubeLink ? (
                <ContentYoutube
                  url={contentEntry.youtubeLink}
                  {...{
                    embedUrl,
                    setEmbedUrl,
                  }}
                />
              ) : (
                ""
              )}

              {contentEntry.artwork ? (
                <ContentArtwork
                  image={contentEntry.artwork}
                  title={contentEntry.title}
                />
              ) : (
                ""
              )}
              {contentEntry.information ? (
                <ContentInfo content={contentEntry.information} />
              ) : (
                ""
              )}
              {contentEntry.spotifyLink ? (
                <ContentSpotify
                  {...{
                    contentEntry,
                    setSpotifyIsActive,
                    spotifyUrl,
                    setSpotifyUrl,
                  }}
                />
              ) : (
                ""
              )}
              <Connections
                {...{
                  contentEntry,
                  entries,
                  setSelectedEntry,
                  historyIndex,
                  historyIds,
                  visibleEntries,
                  setVisibleEntries
                }}
              />
              {contentEntry.externalLinks ? (
                <ContentExternalLinks links={contentEntry.externalLinks} />
              ) : (
                ""
              )}
            </div>
          </section>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentWrapper;
