import { useEffect, useState } from 'react'

const Loading = () => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 3) {
        setProgress((prevProgress) => prevProgress + 1)
      } else {
        setProgress(0)
      }
    }, 100)
    return () => clearInterval(interval)
  })

  return (
    <div className="loading__wrapper container bold">
        <h1>
          Oneohtrix Point Never : Loading
          <span className={`loading__dot ${progress >= 1 ? "active" : ""}`}>
            .
          </span>
          <span className={`loading__dot ${progress >= 2 ? "active" : ""}`}>
            .
          </span>
          <span className={`loading__dot ${progress >= 3 ? "active" : ""}`}>
            .
          </span>
        </h1>
      </div>
  );
};

export default Loading;
