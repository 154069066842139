import { useEffect, useState } from "react";
import Connection from "./Connection";

const ConnectionsSelectedEntry = (props) => {
  const {
    selectedEntry,
    connections,
    trackConnections,
    parentConnections,
    visibleLines,
    containerRef,
    // scale
  } = props;

  const [selectedEntryConnections, setSelectedEntryConnections] = useState([]);

  useEffect(()=>{
    if(selectedEntry !== ''){
      // const entries = 
      const allConnections = [...connections, ...trackConnections, ...parentConnections];
      const filteredConnections = allConnections.filter(
        (entry) =>{
          if(visibleLines[entry.type]){
            return(
              entry.startEntry.id === selectedEntry ||
              entry.connectedElm.id === selectedEntry
            )
          }
        }
         
      );
      setSelectedEntryConnections(filteredConnections);
    }
  },[selectedEntry, connections, trackConnections, parentConnections, visibleLines])

  return (

    <>
      {selectedEntryConnections.map((connection, index) => (
        <Connection
          connection={connection}
          connectionType={connection.type}
          key={index}
          containerRef={containerRef}
          // scale={scale}
        />
      ))}
    </>
  );
};

export default ConnectionsSelectedEntry;
