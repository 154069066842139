import Parents from "./Parents";
import TracksFromWithin from "./TracksFromWithin";
import TracksFromOutside from "./TracksFromOutside";
import References from "./References";

const Connections = (props) => {
  const {
    contentEntry,
    entries,
    setSelectedEntry,
    historyIndex,
    historyIds,
    visibleEntries,
    setVisibleEntries,
  } = props;

  return (
    <>
      <TracksFromWithin
        {...{
          contentEntry,
          setSelectedEntry,
          historyIndex,
          historyIds,
          visibleEntries,
          setVisibleEntries,
        }}
      />
      <Parents
        {...{
          contentEntry,
          entries,
          setSelectedEntry,
          historyIndex,
          historyIds,
          visibleEntries,
          setVisibleEntries,
        }}
      />
      <TracksFromOutside
        {...{
          contentEntry,
          entries,
          setSelectedEntry,
          historyIndex,
          historyIds,
          visibleEntries,
          setVisibleEntries,
        }}
      />
      <References
        {...{
          contentEntry,
          entries,
          setSelectedEntry,
          historyIndex,
          historyIds,
          visibleEntries,
          setVisibleEntries,
        }}
      />
    </>
  );
};

export default Connections;
