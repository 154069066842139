

const Connection = (props) => {
  const { connection, connectionType, containerRef } = props;

  const checkStart = () => {
    const widthInPercent = connection.startEntry.width / (window.innerWidth * 6) * 100;
    let value =
      connection.startEntry.posX +
      widthInPercent -
      connection.connectedElm.posX;
    if (value < 0) {
      return connection.startEntry.posX + widthInPercent;
    } else {
      return connection.startEntry.posX;
    }
  };

  const checkEnd = () => {
    const widthInPercent = (connection.connectedElm.width)/ (window.innerWidth * 6) * 100;
    let value =
      connection.connectedElm.posX +
      widthInPercent -
      connection.startEntry.posX;
    if (value < 0) {
      return connection.connectedElm.posX + widthInPercent;
    } else {
      return connection.connectedElm.posX;
    }
  };


  let coordinates = {
    startX: checkStart(),
    startY: connection.startEntry.posY + (((connection.startEntry.height / 2 ) / containerRef.current.scrollHeight) * 100),
    endX: checkEnd(),
    endY: connection.connectedElm.posY + (((connection.connectedElm.height / 2) / containerRef.current.scrollHeight) * 100),
  };

  // useEffect(()=>{
  //   console.log('fuck');
  //   coordinates = {
  //     startX: checkStart(),
  //     startY: connection.startEntry.posY + ((((connection.startEntry.height * scale) / 2 ) / containerRef.current.scrollHeight) * 100),
  //     endX: checkEnd(),
  //     endY: connection.connectedElm.posY + ((((connection.connectedElm.height * scale) / 2) / containerRef.current.scrollHeight) * 100),
  //   };
  
  // }, [scale])

  return (
    <svg
      width={window.innerWidth * 6}
      height={window.innerHeight * 6}
      className={`connections__wrapper`}
      data-start={connection.startEntry._id}
      data-end={connection.connectedElm._id}
    >
          <line
        x1={`${coordinates.startX}%`}
        y1={`${coordinates.startY}%`}
        x2={`${coordinates.endX}%`}
        y2={`${coordinates.endY}%`}
        className={`connections__line ${connectionType}`}
        fillRule="evenodd" 
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Connection;
