const TracksFromWithin = (props) => {
  const {
    contentEntry,
    setSelectedEntry,
    historyIndex,
    historyIds,
    visibleEntries,
    setVisibleEntries,
  } = props;

  if (contentEntry.tracks) {
    return (
      <>
        <div className="content__section-wrapper bold">
          <h3 className="content__section-title">Tracks:</h3>
          {contentEntry.tracks.map((track, index) => {
            const handleClick = () => {
              setSelectedEntry(track._id);
              historyIds.current.push(track._id);
              historyIndex.current = historyIds.current.length;
              if (!visibleEntries.track) {
                const newVisibleEntries = { ...visibleEntries };
                newVisibleEntries.track = true;
                setVisibleEntries(newVisibleEntries);
              }
            };
            return (
              <button
                className="content__connection-track-wrapper"
                key={index}
                onClick={handleClick}
              >
                <h3 className="content__connection-track-index">
                  {`${index + 1}. `}
                </h3>
                <h3 className="content__connection-track-title">
                  {track.title}
                </h3>
              </button>
            );
          })}
        </div>
      </>
    );
  } else {
    return;
  }
};

export default TracksFromWithin;
