// ON RESIZE, CONVERT THE POSITIONS TO PERCENTAGE VALUES
// THEN CONVERT THEM BACK TO PIXEL VALUES

import { useEffect } from "react";

import Entry from "./Entry";

const Entries = (props) => {
  const {
    siteData,
    entries,
    setEntries,
    selectedEntry,
    setSelectedEntry,
    visibleEntries,
    historyIndex,
    historyIds,
    connections,
    trackConnections,
    parentConnections,
    elmIsDragging, 
    setElmIsDragging,
    isMobile,
    // scale
  } = props;

//   var min = 8.3;
// var max = 91.7;

// var randomNumber = Math.random() * (max - min) + min;


  useEffect(() => {
    if (siteData) {
      const newEntries = siteData
        .filter((entry) => entry._type !== "sanity.imageAsset")
        .map((entry, index) => {
          const modifiedEntry = {
            ...entry,
            id: entry._id,
            title: entry.title,
            type: entry._type,
            index: index,
            // posX: Math.round(Math.random() * (window.innerWidth * 5)),
            // posY: Math.round(Math.random() * (window.innerHeight * 5)),
            // posX: Math.random() * 95,
            // posY: Math.random() * 95,
            posX: Math.random() * (91.7 - 8.3) + 8.3,
            posY: Math.random() * (91.7 - 8.3) + 8.3,
          };
          return modifiedEntry;
        });

      setEntries(newEntries);
    }
  }, [siteData, setEntries]);

  if (entries.length > 0) {
    return (
      <>
        {entries.map((entry, index) => (
          <Entry
            entry={entry}
            entries={entries}
            setEntries={setEntries}
            key={index}
            visibleEntries={visibleEntries}
            selectedEntry={selectedEntry}
            setSelectedEntry={setSelectedEntry}
            historyIndex={historyIndex}
            historyIds={historyIds}
            connections={connections}
            trackConnections={trackConnections}
            parentConnections={parentConnections}
            elmIsDragging={elmIsDragging}
            setElmIsDragging={setElmIsDragging}
            isMobile={isMobile}
            // scale={scale}
          />
        ))}
      </>
    );
  }
};

export default Entries;