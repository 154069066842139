import { useState, useEffect } from "react";

const Preloader = (props) => {
  const { containerRef, isMobile } = props;

  const [preloaderActive, setPreloaderActive] = useState(true);

  const handleClick = () => {
    setPreloaderActive(false);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(
        window.innerWidth * 2.5,
        window.innerHeight * 2.5
      );
    }
  }, [containerRef.current]);

  return;
  // return (
  //   <div
  //     className={`preloader__wrapper bold ${preloaderActive ? "active" : ""}`}
  //   >
  //     <div>
  //       <div className="preloader__mouse-wrapper">
  //         <div className="preloader__mouse-button left"></div>
  //         <div className="preloader__mouse-button right"></div>
  //         <div className="preloader__finger-wrapper">
  //           <div className="preloader__finger index"></div>
  //           <div className="preloader__finger"></div>
  //           <div className="preloader__finger"></div>
  //           <div className="preloader__finger"></div>
  //         </div>
  //         <div className="preloader__arrow-wrapper left">
  //           <img
  //             src="./icons/prev.svg"
  //             alt="click"
  //             className="preloader__arrow"
  //           />
  //         </div>
  //         <div className="preloader__arrow-wrapper right">
  //           <img
  //             src="./icons/next.svg"
  //             alt="drag"
  //             className="preloader__arrow"
  //           />
  //         </div>
  //       </div>
  //       {isMobile ? (
  //         <h1>
  //           Click & Drag
  //           <br />
  //           to explore.
  //         </h1>
  //       ) : (
  //         <h1>
  //           Click & Drag, or
  //           <br />
  //           Scroll to explore.
  //         </h1>
  //       )}
  //       <button
  //         className="preloader__button container bold"
  //         onClick={handleClick}
  //       >
  //         Enter
  //       </button>
  //     </div>
  //   </div>
  // );
};

export default Preloader;
