import { useEffect } from "react";

const ContentYoutube = (props) => {
  const { url, embedUrl, setEmbedUrl } = props;

  function modifyYouTubeUrl(youtubeUrl) {
    let urlWithoutParams;
    if (youtubeUrl.includes("&")) {
      urlWithoutParams = youtubeUrl.split("&")[0];
    } else {
      urlWithoutParams = youtubeUrl;
    }
    let modifiedUrl = urlWithoutParams.replace(
      "https://www.youtube.com/watch?v=",
      ""
    );
    return modifiedUrl;
  }

  useEffect(() => {
    const modifiedUrl = modifyYouTubeUrl(url);
    setEmbedUrl(modifiedUrl);
  }, [url]);

  return (
    <div className="content__section-wrapper youtube">
      <iframe
        src={`https://www.youtube.com/embed/${embedUrl}`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default ContentYoutube;
