import { useEffect, useState } from "react";
import translateType from "../../../hooks/translateType";
import Icon from "../../Interface/Icon";

const ContentTitle = (props) => {
  const { contentEntry } = props;

  return (
    <>
      <div className="content__icon-wrapper">
        {/* <img src="" alt="ICON" />
         */}
         <Icon type={contentEntry.type} />
      </div>
      <div className="content__title bold">
        <div>{translateType(contentEntry.type)}:</div>
        <h3>{contentEntry.title}</h3>
      </div>
    </>
  );
};

export default ContentTitle;
