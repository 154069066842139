import ToggleEntry from "./ToggleEntry";
import ToggleConnection from "./ToggleConnection";

const Key = (props) => {
  const {
    visibleEntries,
    setVisibleEntries,
    visibleLines,
    setVisibleLines,
    keyIsActive,
    setKeyIsActive,
    spotifyIsActive,
  } = props;

  return (
    <>
      <div
        className={`key__container
    ${keyIsActive ? "key-open" : ""} 
    ${spotifyIsActive ? "spotify-open" : ""}
    `}
      >
        <button
          className="key__close-button close-button container"
          onClick={() => setKeyIsActive(false)}
        ></button>
        <div
          className={`key__outer-wrapper container
    `}
        >
          <div className="key__title-wrapper bold">
            <h2>Key :</h2>
          </div>
          <div
            className="key__inner-wrapper"
          >
            <div className="key__section-wrapper">
              <h3 className="key__section-title bold">Releases:</h3>
              <ToggleEntry
                entryType={"album"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"ep"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"miniAlbum"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"collaboration"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"single"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"track"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"liveShow"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"liveRecording"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"radioShow"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"filmScore"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"video"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
              <ToggleEntry
                entryType={"ref"}
                {...{
                  visibleEntries,
                  setVisibleEntries,
                  visibleLines,
                  setVisibleLines,
                }}
              />
            </div>
            <div className="interface__key-section-wrapper">
              <h3 className="key__section-title bold">Connections:</h3>

              <ToggleConnection
                connectionType={"track"}
                {...{
                  visibleLines,
                  setVisibleLines,
                  visibleEntries,
                  setVisibleEntries,
                }}
              />
              <ToggleConnection
                connectionType={"ref"}
                {...{
                  visibleLines,
                  setVisibleLines,
                  visibleEntries,
                  setVisibleEntries,
                }}
              />
              <ToggleConnection
                connectionType={"parent"}
                {...{
                  visibleLines,
                  setVisibleLines,
                  visibleEntries,
                  setVisibleEntries,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Key;
