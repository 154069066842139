import { useEffect } from "react";
import Connection from "./Connection";

const ConnectionsTracks = (props) => {
  const {
    entries,
    trackConnections,
    setTrackConnections,
    visibleLines,
    visibleEntries,
    containerRef,
    // scale
  } = props;

  useEffect(() => {
    if (entries.length > 0) {
      const newTracks = [];
      const entriesWithTracks = entries.filter(
        (entry) =>
          entry._type === "album" ||
          entry._type === "collaboration" ||
          entry._type === "ep" ||
          entry._type === "filmScore" ||
          entry._type === "miniAlbum" ||
          entry._type === "single"
      );

      entriesWithTracks.forEach((entry) => {
        if (entry.tracks !== null) {
          entry.tracks.forEach((track) => {
            const startEntry = entry;
            const connectedElm = entries.find((obj) => obj?.id === track?._id);

            if (connectedElm) {
              const connection = {
                type: "track",
                startEntry,
                connectedElm,
              };
              if (
                visibleEntries[startEntry.type] &&
                visibleEntries[connectedElm.type]
              ) {
                newTracks.push(connection);
              }
            }
          });
        }
      });

      setTrackConnections(newTracks);
    }
  }, [entries, setTrackConnections, visibleEntries]);

  return (

    <>
      {visibleLines.track && trackConnections.map((connection, index) => (
        <Connection
          connection={connection}
          connectionType={connection.type}
          key={index}
          containerRef={containerRef}
          // scale={scale}
        />
      ))}
    </>
 
  );
};

export default ConnectionsTracks;
