import { useEffect, useRef, useState } from "react";
import Icon from "../Interface/Icon";

const Entry = (props) => {
  const {
    entry,
    entries,
    setEntries,
    selectedEntry,
    setSelectedEntry,
    visibleEntries,
    historyIndex,
    historyIds,
    setElmIsDragging,
    isMobile,
    // scale
  } = props;

  const entryRef = useRef();
  const isDragging = useRef(false);
  const offsetX = useRef(0);
  const offsetY = useRef(0);

  useEffect(() => {
    if (entryRef.current) {
      const width = entryRef.current.offsetWidth;
      const height = entryRef.current.offsetHeight;
      const newEntries = [...entries];
      newEntries[entry.index].width = width;
      newEntries[entry.index].height = height;
      setEntries(newEntries);
    }
  }, [entryRef, entry]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (entryRef.current && isDragging.current) {
        event.preventDefault();
        const newEntries = [...entries];
        newEntries[entry.index].posX =
          (event.clientX / (window.innerWidth * 6)) * 100 - offsetX.current;
        newEntries[entry.index].posY =
          (event.clientY / (window.innerHeight * 6)) * 100 - offsetY.current;
        setEntries(newEntries);
      }
    };

    const handleTouchMove = (event) => {
      if (entryRef.current && isDragging.current) {
        const newEntries = [...entries];

        event.preventDefault();
        let touch = event.touches[0];

        newEntries[entry.index].posX =
          (touch.clientX / (window.innerWidth * 6)) * 100 - offsetX.current;
          
        newEntries[entry.index].posY =
          (touch.clientY / (window.innerHeight * 6)) * 100 - offsetY.current;
        setEntries(newEntries);
      }
    };

    const handleMouseUp = () => {
      if (isDragging.current) {
        isDragging.current = false;
      }
      setElmIsDragging(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    document.addEventListener("pointerup", handleMouseUp);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove, {
        passive: false,
      });

      document.removeEventListener("pointerup", handleMouseUp);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [entryRef, isDragging, offsetX, offsetY, setEntries, entries]);

  const handleMouseDown = (event) => {
    event.preventDefault();
    isDragging.current = true;
    setElmIsDragging(true);
    offsetX.current =
      (event.clientX / (window.innerWidth * 6)) * 100 - entry.posX;

    offsetY.current =
      (event.clientY / (window.innerHeight * 6)) * 100 - entry.posY;
  };

  const handleSelectEntry = () => {
    if (selectedEntry === entry.id) {
      setSelectedEntry("");
    } else {
      setSelectedEntry(entry.id);
      historyIds.current.push(entry.id);
      historyIndex.current = historyIds.current.length;
    }
  };

  // useEffect(()=>{

  // } ,[scale])

  if (!isMobile) {
    return (
      <div
        className={`entry__outer-wrapper ${
          selectedEntry === entry.id ? "active" : ""
        }
        ${visibleEntries[entry.type] ? "" : "disabled"}
        ${selectedEntry ? "opacity" : ""}
        
        `}
        style={{ left: `${entry.posX}%`, top: `${entry.posY}%` }}
        ref={entryRef}
        data-id={entry.id}
      >
        <div className="entry__click-wrapper" onClick={handleSelectEntry}>
          <div className="entry__icon-wrapper">
            <Icon type={entry.type} />
          </div>
          {entry.title}
        </div>

        <div className="entry__drag-button" onMouseDown={handleMouseDown}>
          <img src="/icons/grabDots.svg" alt="Click & Drag" />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`entry__outer-wrapper ${
          selectedEntry === entry.id ? "active" : ""
        }
        ${visibleEntries[entry.type] ? "" : "disabled"}
        ${selectedEntry ? "opacity" : ""}
        
        `}
        style={{ left: `${entry.posX}%`, top: `${entry.posY}%` }}
        ref={entryRef}
        data-id={entry.id}
      >
        <div className="entry__click-wrapper" onClick={handleSelectEntry}>
          <div className="entry__icon-wrapper">
            <Icon type={entry.type} />
          </div>
          {entry.title}
        </div>

        <div className="entry__drag-button" onPointerDown={handleMouseDown}>
          <img src="/icons/grabDots.svg" alt="Click & Drag" />
        </div>
      </div>
    );
  }
};

export default Entry;
