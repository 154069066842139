import { useEffect, useState } from "react";
import Icon from "./Icon";

const ToggleEntry = (props) => {
  const {
    entryType,
    visibleEntries,
    setVisibleEntries,
    visibleLines,
    setVisibleLines,
  } = props;

  const toggleVisibleEntries = () => {
    const newVisibleEntries = { ...visibleEntries };
    if (newVisibleEntries[entryType]) {
      newVisibleEntries[entryType] = false;
    } else {
      newVisibleEntries.all = true;
      newVisibleEntries[entryType] = true;
    }
    setVisibleEntries(newVisibleEntries);
  };

  const [translatedEntryType, setTransEntryType] = useState("");

  useEffect(() => {
    switch (entryType) {
      case "all":
        setTransEntryType("All");
        break;
      case "album":
        setTransEntryType("Albums");
        break;
      case "collaboration":
        setTransEntryType("Collaborations");
        break;
      case "ep":
        setTransEntryType("EPs");
        break;
      case "filmScore":
        setTransEntryType("Film Scores");
        break;
      case "liveRecording":
        setTransEntryType("Live Recordings");
        break;
      case "liveShow":
        setTransEntryType("Live Shows");
        break;
      case "miniAlbum":
        setTransEntryType("Mini Albums");
        break;
      case "radioShow":
        setTransEntryType("Radio Shows");
        break;
      case "ref":
        setTransEntryType("References");
        break;
      case "single":
        setTransEntryType("Singles");
        break;
      case "track":
        setTransEntryType("Tracks");
        break;
      case "video":
        setTransEntryType("Videos");
        break;
      default:
        setTransEntryType("All");
        break;
    }
  }, [entryType]);

  return (
    <div className="key__entry-wrapper">
      <div className="key__icon-wrapper">
        <Icon type={entryType} />
      </div>
      <div className="key__entry-text">{translatedEntryType}</div>
      <div className="key__toggle-wrapper">
        <div className="key__toggle-green"></div>
        <button
          className={`key__toggle-button ${visibleEntries[entryType] ? "active" : ""}`}
          onClick={
            toggleVisibleEntries
          }
        >
        </button>
      </div>
    </div>
  );
};

export default ToggleEntry;
