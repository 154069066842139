import { useEffect } from "react";
import { scrollTo } from "seamless-scroll-polyfill";

const MouseControls = (props) => {
  const {
    entries,
    selectedEntry,
    containerRef,
    scale,
    setScale,

    startX,
    startY,
    scrollLeft,
    scrollTop,

    visibleEntries,
    visibleLines,
  } = props;

  // const logScroll = () => {
  //   const coords = {
  //     totalHeight: containerRef.current.scrollHeight,
  //     scrollPos: containerRef.current.scrollTop,
  //     scrollablepix:
  //       containerRef.current.scrollHeight - containerRef.current.offsetHeight,
  //     height: containerRef.current.offsetHeight,
  //   };
  //   console.log(coords);
  // };

  const handleZoomIn = () => {
    const targetScale = (scale + 0.2).toFixed(1);

    let scaleFactor = 0;
    switch (targetScale) {
      case "1.0":
        scaleFactor = 1.3;
        break;
      case "0.8":
        scaleFactor = 1.44;
        break;
      case "0.6":
        scaleFactor = 1.79;
        break;
      case "0.4":
        scaleFactor = 4.93;
        break;
      default:
        scaleFactor = 0.2039;
        break;
    }

    const scrollCoords = {
      top: containerRef.current.scrollTop * scaleFactor,
      left: containerRef.current.scrollLeft * scaleFactor,
    };
    setScale((prevScale) => (prevScale += 0.2));
    scrollTo(containerRef.current, scrollCoords);

    startX.current = containerRef.current.scrollLeft + window.innerWidth / 2;
    startY.current = containerRef.current.scrollTop + window.innerHeight / 2;
    scrollLeft.current = containerRef.current.scrollLeft;
    scrollTop.current = containerRef.current.scrollTop;
  };

  const handleZoomOut = () => {
    const targetScale = (scale - 0.2).toFixed(1);

    let scaleFactor = 0;
    switch (targetScale) {
      case "0.8":
        scaleFactor = 0.7677;
        break;
      case "0.6":
        scaleFactor = 0.6919;
        break;
      case "0.4":
        scaleFactor = 0.5564;
        break;
      case "0.2":
        scaleFactor = 0.2039;
        break;
      default:
        scaleFactor = 0.2039;
        break;
    }
    const scrollCoords = {
      top: containerRef.current.scrollTop * scaleFactor,
      left: containerRef.current.scrollLeft * scaleFactor,
    };
    setScale((prevScale) => (prevScale -= 0.2));
    scrollTo(containerRef.current, scrollCoords);
    startX.current = containerRef.current.scrollLeft + window.innerWidth / 2;
    startY.current = containerRef.current.scrollTop + window.innerHeight / 2;
    scrollLeft.current = containerRef.current.scrollLeft;
    scrollTop.current = containerRef.current.scrollTop;
  };

  //TESTING
  useEffect(() => {
    if (selectedEntry !== "") {
      const filteredEntries = entries.filter((entry) => {
        return entry.id === selectedEntry;
      });

      if (filteredEntries[0]) {
        const entry = filteredEntries[0];

        const entryElm = document.querySelector(`[data-id="${entry.id}"]`);

        const elmTop = entryElm.getBoundingClientRect().top;
        const halfHeight = entryElm.getBoundingClientRect().height / 2;
        const elmLeft = entryElm.getBoundingClientRect().left;
        const halfWidth = entryElm.getBoundingClientRect().width / 2;

        const top =
          elmTop +
          containerRef.current.scrollTop +
          halfHeight -
          window.innerHeight / 2;
        const left =
          elmLeft +
          containerRef.current.scrollLeft +
          halfWidth -
          window.innerWidth / 2;

        startX.current = left + window.innerWidth / 2;
        startY.current = top + window.innerHeight / 2;
        scrollLeft.current = left;
        scrollTop.current = top;

        const scrollCoords = {
          top: top,
          left: left,
          behavior: "smooth",
        };
        scrollTo(containerRef.current, scrollCoords);
      }
    }
  }, [selectedEntry]);

  useEffect(() => {
    function isInArray(array, value) {
      return array.includes(value);
    }

    const returnEntry = () => {
      const test = entries.find((entry) => entry._id === selectedEntry);
      return test;
    };

    const checkAllConnections = (entry) => {
      if (entry) {
        const tracksFromWithin = entry.tracks;
        const tracksFromOutside = entries.filter((check) =>
          check.tracks?.some((track) => track._id === entry._id)
        );
        const refsFromWithin = entry.references;
        const refsFromOutside = entries.filter((check) =>
          check.references?.some((ref) => {
            if (ref.refRef?._id === entry._id) {
              return ref.refRef;
            }
            
          })
        );
        const parentFromWithin = entry.parentRelease;
        const parentFromOutside = entries.filter(
          (check) =>
            check.parentRelease && check.parentRelease._id === entry._id
        );

        const ids = [];

        if (tracksFromWithin) {
          tracksFromWithin.forEach((track) => ids.push(track._id));
        }
        if (tracksFromOutside) {
          tracksFromOutside.forEach((track) => ids.push(track._id));
        }
        if (refsFromWithin) {
          refsFromWithin.forEach((ref) => ids.push(ref.refRef._id));
        }
        if (refsFromOutside) {
          refsFromOutside.forEach((ref) => ids.push(ref._id));
        }
        if (parentFromWithin) {
          ids.push(parentFromWithin._id);
        }
        if (parentFromOutside) {
          parentFromOutside.forEach((parent) => ids.push(parent._id));
        }

        const elements = document.querySelectorAll(".entry__outer-wrapper");
        elements.forEach((elm) => {
          elm.classList.remove("highlight");
          if (
            isInArray(ids, elm.dataset.id) ||
            elm.dataset.id === selectedEntry
          ) {
            elm.classList.add("highlight");
          }
        });
      }
    };

    if (selectedEntry !== "") {
      checkAllConnections(returnEntry(selectedEntry));
    }
  }, [selectedEntry, visibleEntries, visibleLines]);

  return (
    <>
      <div className="interface__zoom-wrapper bold">
        {/* <button
          className="interface__zoom-button container active"
          onClick={logScroll}
        >
          log
        </button> */}
        <button
          className={`interface__zoom-button container ${
            scale < 1.0 ? "active" : ""
          }`}
          onClick={handleZoomIn}
        >
          Zoom In
        </button>
        <button
          className={`interface__zoom-button container ${
            scale > 0.6 ? "active" : ""
          }`}
          onClick={handleZoomOut}
        >
          Zoom Out
        </button>
      </div>
    </>
  );
};

export default MouseControls;
