import { useEffect, useState } from 'react'
import Icon from './Icon';

const ListViewEntry = (props) => {

    const {
        entry,
        selectedEntry,
        setSelectedEntry,
        visibleEntries,
        setVisibleEntries,
        isMobile,
        setListViewActive,
        historyIds,
        historyIndex
    } = props;

    const [releaseDate, setReleaseDate] = useState('')
    useEffect(()=>{
        if(entry.releaseDate){
            const releaseDate = new Date(entry.releaseDate);
            const month = (releaseDate.getMonth() + 1).toString().padStart(2, '0');
            const day = releaseDate.getDate().toString().padStart(2, '0');
            const year = releaseDate.getFullYear();
            setReleaseDate(`${month}/${day}/${year}`);
        }
    })

    const handleClick = () => {
        setSelectedEntry(entry.id);
        historyIds.current.push(entry.id);
      historyIndex.current = historyIds.current.length;
        if (!visibleEntries[entry.type]) {
            const newVisibleEntries = { ...visibleEntries };
            newVisibleEntries[entry.type] = true;
            setVisibleEntries(newVisibleEntries);
          }
        if(isMobile){
            setListViewActive(false)
        }
    }
    
  return (
    <button className={`list-view__entry-wrapper bold ${selectedEntry === entry.id? 'active': ''}`}
        onClick={handleClick}>
        <div className='list-view__entry-icon'>
        <Icon type={entry.type} />
        </div>
        <div className='list-view__entry-title'>
        {entry.title}
        </div>
        <div className='list-view__entry-date'>
        {releaseDate}
        </div>
         
        </button>
  )
}

export default ListViewEntry