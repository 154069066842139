const ContentSpotify = (props) => {
  const { contentEntry, setSpotifyIsActive, setSpotifyUrl } = props;

  function modifySpotifyUrl(spotifyUrl) {
    let urlWithoutQuery;
    if (spotifyUrl.includes("?")) {
      urlWithoutQuery = spotifyUrl.split("?")[0];
    } else {
      urlWithoutQuery = spotifyUrl;
    }
    let modifiedUrl = urlWithoutQuery.replace("https://open.spotify.com/", "");
    return modifiedUrl;
  }

  const handleClick = () => {
    setSpotifyIsActive(true);
    let modifiedUrl = modifySpotifyUrl(contentEntry.spotifyLink);
    setSpotifyUrl(modifiedUrl);
  };

  return (
    <div className="content__section-wrapper bold">
      <button onClick={handleClick}  className="content__connection-spotify-wrapper">
      <div className="content__connection-icon no-padding">
                  <img src="/icons/listen.svg" alt="Listen" />
                </div>
                <div className="content__connection-text listen">
                  <h3>Listen</h3>
                </div>
      </button>
    </div>
  );
};

export default ContentSpotify;
