import { useEffect } from "react";
import Connection from "./Connection";

const ConnectionsRefs = (props) => {
  const { entries, connections, setConnections, 
    visibleLines, 
    visibleEntries,
    containerRef,
  // scale 
} = props;

  useEffect(() => {
    if (entries.length > 0) {
      const newConnections = [];

      entries.forEach((entry) => {
        if (entry.references !== null) {
          entry.references.forEach((connection) => {
            const startEntry = entry;
            const connectedElm = entries.find(
              (obj) => obj?.id === connection.refRef?._id
            );
            if (connectedElm) {
              const connection = {
                type: "ref",
                startEntry,
                connectedElm,
              };
              if (
                visibleEntries[startEntry.type] &&
                visibleEntries[connectedElm.type]
              ) {
                newConnections.push(connection);
              }
            }
          });
        }
      });

      setConnections(newConnections);
    }
  }, [entries, setConnections, visibleEntries]);

  return (
    <>
      {visibleLines.ref && connections.map((connection, index) => (
        <Connection
          connection={connection}
          connectionType={connection.type}
          key={index}
          containerRef={containerRef}
          // scale={scale}
        />
      ))}
    </>
  );
};

export default ConnectionsRefs;
