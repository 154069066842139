import { useEffect, useRef, useState } from "react";
import ListViewEntry from "./ListViewEntry";
import ListViewSort from "./ListViewSort";

const ListView = (props) => {
  const {
    entries,
    selectedEntry,
    setSelectedEntry,
    listViewActive,
    setListViewActive,
    spotifyIsActive,
    visibleEntries,
    setVisibleEntries,
    isMobile,
    historyIds,
    historyIndex
  } = props;

  const listWrapperRef = useRef()
  const [listViewEntries, setListViewEntries] = useState([]);
  const [sortMethod, setSortMethod] = useState({
    type: "date",
    direction: "desc",
  });

  const sortByTitle = (arr, order) => {
    return arr
      .slice()
      .sort((a, b) =>
        order === "asc"
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title)
      );
  };

  const sortByDate = (arr, order) => {
    return arr
      .slice()
      .sort((a, b) =>
        order === "asc"
          ? new Date(a.releaseDate) - new Date(b.releaseDate)
          : new Date(b.releaseDate) - new Date(a.releaseDate)
      );
  };

  const sortByType = (arr, order = "asc") => {
    return arr
      .slice()
      .sort((a, b) =>
        order === "asc"
          ? a.type.localeCompare(b.type)
          : b.type.localeCompare(a.type)
      );
  };

  const sortedByTitleAsc = sortByTitle(listViewEntries, "desc");
  const sortedByTitleDesc = sortByTitle(listViewEntries, "asc");
  const sortedByDateAsc = sortByDate(listViewEntries, "asc");
  const sortedByDateDesc = sortByDate(listViewEntries, "desc");
  const sortedByTypeAsc = sortByType(listViewEntries, "asc");
  const sortedByTypeDesc = sortByType(listViewEntries, "desc");

  const returnToTop = () => {
    if (listWrapperRef.current) {
      listWrapperRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (sortMethod.type === "date" && sortMethod.direction === "desc") {
      setListViewEntries(sortedByDateDesc);
    } else if (sortMethod.type === "date" && sortMethod.direction === "asc") {
      setListViewEntries(sortedByDateAsc);
    } else if (sortMethod.type === "title" && sortMethod.direction === "desc") {
      setListViewEntries(sortedByTitleDesc);
    } else if (sortMethod.type === "title" && sortMethod.direction === "asc") {
      setListViewEntries(sortedByTitleAsc);
    } else if (sortMethod.type === "type" && sortMethod.direction === "desc") {
      setListViewEntries(sortedByTypeDesc);
    } else if (sortMethod.type === "type" && sortMethod.direction === "asc") {
      setListViewEntries(sortedByTypeAsc);
    } else {
      setListViewEntries(sortedByDateDesc);
    }
    returnToTop();
  }, [sortMethod]);


  useEffect(() => {
    const newEntries = entries
      .filter(
        (entry) =>
          entry._type !== "sanity.imageAsset" &&
          entry._type !== "track" &&
          entry._type !== "ref" &&
          entry._type !== "information"
      )
      .map((entry) => {
        const modifiedEntry = {
          releaseDate: entry.releaseDate,
          id: entry._id,
          title: entry.title,
          type: entry._type,
        };
        return modifiedEntry;
      });

    const sortedByDate = sortByDate(newEntries, "desc");
    setListViewEntries(sortedByDate);
  }, [entries]);

  return (
    <section
      className={`list-view__outer-wrapper container 
    ${listViewActive ? "active" : ""}
    ${spotifyIsActive ? "spotify-open" : ""}
    `}
    >
      <button
        className="list-view__close close-button container"
        onClick={() => {
          setListViewActive(false);
          returnToTop();
        }}
      ></button>
      <div className="list-view__title-wrapper bold">
        <h2>List View :</h2>

        <div className="list-view__sort-buttons-wrapper">
          <ListViewSort
            {...{
              sortMethod,
              setSortMethod,
              type: "type",
            }}
          />
          <ListViewSort
            {...{
              sortMethod,
              setSortMethod,
              type: "title",
            }}
          />
          <ListViewSort
            {...{
              sortMethod,
              setSortMethod,
              type: "date",
            }}
          />
        </div>
      </div>
      <div className="list-view__inner-wrapper" ref={listWrapperRef}>
        {listViewEntries &&
          listViewEntries.map((entry, index) => {
            return (
              <ListViewEntry
                entry={entry}
                selectedEntry={selectedEntry}
                setSelectedEntry={setSelectedEntry}
                key={index}
                visibleEntries={visibleEntries}
                setVisibleEntries={setVisibleEntries}
                isMobile={isMobile}
                setListViewActive={setListViewActive}
                historyIds={historyIds}
                historyIndex={historyIndex}
              />
            );
          })}
      </div>
    </section>
  );
};

export default ListView;
