import { useEffect, useState } from "react";
import Icon from "../../Interface/Icon";
import translateType from "../../../hooks/translateType";

const Parents = (props) => {
  const {
    entries,
    contentEntry,
    setSelectedEntry,
    historyIndex,
    historyIds,
    visibleEntries,
    setVisibleEntries,
  } = props;

  const [parents, setParents] = useState([]);

  useEffect(() => {
    const filteredEntries = entries.filter(
      (entry) =>
        entry.parentRelease && entry.parentRelease._id === contentEntry.id
    );
    setParents(filteredEntries);
  }, [contentEntry, entries]);

  if (parents.length > 0 || contentEntry.parentRelease) {
    return (
      <>
        <div className="content__section-wrapper bold">
          <div className="content__connections-wrapper">
            <h3 className="content__section-title">Released with:</h3>
            {contentEntry.parentRelease !== null ? (
              
              <button
                className="content__connection-ref-wrapper"
                onClick={() => {
                  setSelectedEntry(contentEntry.parentRelease._id);
                  historyIds.current.push(contentEntry.parentRelease._id);
                  historyIndex.current = historyIds.current.length;
                  if (!visibleEntries[contentEntry.parentRelease._type]) {
                    const newVisibleEntries = { ...visibleEntries };
                    newVisibleEntries[contentEntry.parentRelease._type] = true;
                    setVisibleEntries(newVisibleEntries);
                  }
                }}
              >
                <div className="content__connection-icon">
                  <Icon type={contentEntry.parentRelease._type} />
                </div>
                <div className="content__connection-text">
                  <div>{translateType(contentEntry.parentRelease._type)}:</div>
                  <h3>{contentEntry.parentRelease.title}</h3>
                </div>
              </button>
            ) : (
              ""
            )}
            {parents.map((parent, index) => {
              const handleClick = () => {
                setSelectedEntry(parent.id);
                historyIds.current.push(parent.id);
                historyIndex.current = historyIds.current.length;
                if (!visibleEntries[parent.type]) {
                  const newVisibleEntries = { ...visibleEntries };
                  newVisibleEntries[parent.type] = true;
                  setVisibleEntries(newVisibleEntries);
                }
              };
              return (
                <button
                  className="content__connection-ref-wrapper"
                  key={index}
                  onClick={handleClick}
                >
                  <div className="content__connection-icon">
                    <Icon type={parent.type} />
                  </div>
                  <div className="content__connection-text">
                    <div>{translateType(parent.type)}:</div>
                    <h3>{parent.title}</h3>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </>
    );
  } else {
    return;
  }
};

export default Parents;
