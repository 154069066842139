import sanityClient from "../../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../../hooks/useProgressiveImage.js";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ContentArtwork = (props) => {
  const { title, image } = props;

  const smallUrl = `${urlFor(image)}?q=5`;
  const largeUrl = `${urlFor(image)}`;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <div className="content__section-wrapper">
      <img
        src={src}
        alt={title}
        className="content__artwork"
        style={{
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.4s ease-out",
        }}
      />
    </div>
  );
};

export default ContentArtwork;
