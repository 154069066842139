import { useEffect, useState } from "react";
import sanityClient from "../../client.js";
import InfoArtwork from "./InfoArtwork";
import InfoText from "./InfoText.js";

const Information = (props) => {
  const { infoActive, setInfoActive, spotifyIsActive } = props;

  const [infoData, setInfoData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'information'][0]`)
      .then((data) => {
        setInfoData(data);
      })
      .catch();
  }, []);

  return (
    <div
      className={`info__container
        ${infoActive ? "info-open" : ""} 
        ${spotifyIsActive ? "spotify-open" : ""}
        `}
    >
      <button
        className="info__close-button close-button container"
        onClick={() => setInfoActive(false)}
      ></button>
      <div className={`info__outer-wrapper container`}>
        <div className="info__title-wrapper bold">
          <h2>Info :</h2>
        </div>
        {infoData ? (
          <div className="info__inner-wrapper">
            <InfoText content={infoData.information} />
            <InfoArtwork image={infoData.artwork} />
          </div>
        ) : (
          "Loading..."
        )}
        <div className="info__credit bold">
          Website by:{" "}
          <a
            href="https://www.voidmain.studio/"
            target="_blank"
            rel="noreferrer"
          >
            VOID MAIN()
          </a>
        </div>
      </div>
    </div>
  );
};

export default Information;
