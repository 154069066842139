import { useEffect } from "react";
import Connection from "./Connection";

const ConnectionsParents = (props) => {
  const {
    entries,
    parentConnections,
    setParentConnections,
    visibleLines,
    visibleEntries,
    containerRef,
    // scale
  } = props;

  useEffect(() => {
    if (entries.length > 0) {
      const newEntries = [];
      const entriesWithParents = entries.filter(
        (entry) =>
          entry._type === "collaboration" ||
          entry._type === "ep" ||
          entry._type === "liveRecording" ||
          entry._type === "liveShow" ||
          entry._type === "miniAlbum" ||
          entry._type === "radioShow" ||
          entry._type === "single" ||
          entry._type === "video"
      );

      entriesWithParents.forEach((entry) => {
        if (entry.parentRelease !== null) {
          const startEntry = entry;
          const connectedElm = entries.find(
            (obj) => obj?.id === entry.parentRelease?._id
          );
          if (connectedElm) {
            const connetion = {
              type: "parent",
              startEntry,
              connectedElm,
            };
            if (
              visibleEntries[startEntry.type] &&
              visibleEntries[connectedElm.type]
            ) {
              newEntries.push(connetion);
            }
          }
        }
      });

      setParentConnections(newEntries);
    }
  }, [entries, setParentConnections, visibleEntries]);

  return (
    <>
      {visibleLines.parent &&
        parentConnections.map((connection, index) => (
          <Connection
            connection={connection}
            connectionType={connection.type}
            key={index}
            containerRef={containerRef}
            // scale={scale}
          />
        ))}
    </>
  );
};

export default ConnectionsParents;
