import { useState, useEffect } from "react";
import Icon from "../../Interface/Icon";

const References = (props) => {
  const {
    entries,
    contentEntry,
    setSelectedEntry,
    historyIndex,
    historyIds,
    visibleEntries,
    setVisibleEntries,
  } = props;

  const [references, setReferences] = useState([]);

  useEffect(() => {
    let filteredEntries = [];
    entries.forEach((entry) => {
      if (entry.references) {
        entry.references.forEach((ref) => {
          if (ref.refRef && ref.refRef._id === contentEntry.id) {
            filteredEntries.push({
              connectionTitle: ref.title,
              title: entry.title,
              type: entry._type,
              id: entry._id,
            });
          }
        });
      }
    });

    if (contentEntry.references) {
      contentEntry.references.forEach((ref) => {
        if (ref.refRef) {
          filteredEntries.push({
            connectionTitle: ref.title,
            title: ref.refRef.title,
            type: ref.refRef._type,
            id: ref.refRef._id,
          });
        }
      });
    }
    setReferences(filteredEntries);
  }, [contentEntry, entries]);

  if (references.length > 0) {
    return (
      <>
        <div className="content__section-wrapper bold">
          <h3 className="content__section-title">Connections:</h3>
          {references.map((reference, index) => {
            const handleClick = () => {
              setSelectedEntry(reference.id);
              historyIds.current.push(reference.id);
              historyIndex.current = historyIds.current.length;
              if (!visibleEntries[reference.type]) {
                const newVisibleEntries = { ...visibleEntries };
                newVisibleEntries[reference.type] = true;
                setVisibleEntries(newVisibleEntries);
              }
            };
            return (
              <button
                className="content__connection-ref-wrapper"
                key={index}
                onClick={handleClick}
              >
                <div className="content__connection-icon">
                  <Icon type={reference.type} />
                </div>
                <div className="content__connection-text">
                  <div>
                    {reference.connectionTitle
                      ? reference.connectionTitle
                      : "Connection"}
                    :
                  </div>
                  <h3>{reference.title}</h3>
                </div>
              </button>
            );
          })}
        </div>
      </>
    );
  } else {
    return;
  }
};
export default References;
