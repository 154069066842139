import React from "react";

const Header = (props) => {
  const { 
    keyIsActive,
    setKeyIsActive,
    listViewActive,
    setListViewActive,
    infoActive,
    setInfoActive,
   } =
    props;

  return (
    <>
      <div className="interface__header-wrapper container bold">
        <h1>Oneohtrix Point Never : Archive</h1>
      </div>
      <div
        className={`interface__nav-wrapper ${
          keyIsActive || listViewActive || infoActive ? "disabled" : ""
        }`}
      >
        <button
          className={`interface__nav-button container bold `}
          onClick={() => {
            setKeyIsActive(true);
            setListViewActive(false);
            setInfoActive(false);
          }}
        >
          <h3>Key</h3>
        </button>
        <button
          className={`interface__nav-button container bold `}
          onClick={() => {
            setListViewActive(true);
            setKeyIsActive(false);
            setInfoActive(false);
          }}
        >
          <h3>List View</h3>
        </button>
        <button
          className={`interface__nav-button container bold `}
          onClick={() => {
            setInfoActive(true);
            setKeyIsActive(false);
            setListViewActive(false);
          }}
        >
          <h3>About</h3>
        </button>
        <a href="https://live.pointnever.com/" target="_blank" rel="noreferrer" className="interface__nav-button container bold">
         <h3>Info / Live</h3>
        </a>
        <a href="https://opn.ffm.to/merch" target="_blank" rel="noreferrer" className="interface__nav-button container bold">
         <h3>Merch</h3>
        </a>
      </div>
    </>
  );
};

export default Header;
