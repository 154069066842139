const translateType = (type) => {

    let translation;
    switch (type) {
        case "album":
          translation = "Album";
          break;
        case "collaboration":
          translation = "Collaboration";
          break;
        case "ep":
          translation = "EP";
          break;
        case "filmScore":
          translation = "Film Score";
          break;
        case "liveRecording":
          translation = "Live Recording";
          break;
        case "liveShow":
          translation = "Live Show";
          break;
        case "miniAlbum":
          translation = "Mini Album";
          break;
        case "radioShow":
          translation = "Radio Show";
          break;
        case "ref":
          translation = "Reference";
          break;
        case "single":
          translation = "Single";
          break;
        case "track":
          translation = "Track";
          break;
        case "video":
          translation = "Video";
          break;
        default:
          translation = "All";
          break;
      }
      return translation;

}

export default translateType;
