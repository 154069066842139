import Icon from "./Icon";

const History = (props) => {
  const {
    entries,
    setSelectedEntry,
    historyIndex,
    historyIds,
    visibleEntries,
    setVisibleEntries,
  } = props;

  const checkAndMakeVisible = (id) => {
    const type = entries.find(x => x.id === id).type;
    if (!visibleEntries[type]) {
      const newVisibleEntries = { ...visibleEntries };
      newVisibleEntries[type] = true;
      setVisibleEntries(newVisibleEntries);
    }
  }

  const handlePrev = () => {
    historyIndex.current -= 1;
    setSelectedEntry(historyIds.current[historyIndex.current - 1]);
    checkAndMakeVisible(historyIds.current[historyIndex.current - 1])
  };

  const handleNext = () => {
    historyIndex.current += 1;
    setSelectedEntry(historyIds.current[historyIndex.current - 1]);
    checkAndMakeVisible(historyIds.current[historyIndex.current - 1])
  };

  return (
    <div className="content__history-wrapper bold">
      <button
        className={`content__history-button prev container ${
          historyIndex.current > 1 ? "" : "disabled"
        }`}
        onClick={handlePrev}
      >
        <div
          className={`content__history-arrow prev ${
            historyIndex.current > 1 ? "" : "disabled"
          }`}
        >
          <Icon type={"prev"} />
        </div>
        Previous
      </button>

      <button
        className={`content__history-button next container ${
          historyIndex.current <= historyIds.current.length - 1
            ? ""
            : "disabled"
        }`}
        onClick={handleNext}
      >
        next
        <div
          className={`content__history-arrow next ${
            historyIndex.current <= historyIds.current.length - 1
              ? ""
              : "disabled"
          }`}
        >
          <Icon type={"next"} />
        </div>
      </button>
    </div>
  );
};

export default History;
