import { useEffect, useState } from "react";

const Spotify = (props) => {
  const { spotifyIsActive, setSpotifyIsActive, spotifyUrl, setSpotifyUrl } =
    props;

  const handleClose = () => {
    setSpotifyIsActive(false);
    setSpotifyLoaded(false);
    setTimeout(() => {
      setSpotifyUrl(null);
    }, 400);
  };

  const [spotifyLoaded, setSpotifyLoaded] = useState(false);
  const handleLoaded = () => {
    setTimeout(()=>{
      setSpotifyLoaded(true);
    }, 3000)
  };

  useEffect(()=>{
    setTimeout(()=>{
      setSpotifyLoaded(true);
    }, 3000)
  }, [spotifyUrl])


  return (
    <div className={`spotify__container ${spotifyIsActive ? "active" : ""}`}>
      

      <button
        className="spotify__close-button close-button container"
        onClick={handleClose}
      >
        {" "}
      </button>
      <div className={`spotify__outer-wrapper container spotify`}>
        <div className={`spotify__loading ${spotifyLoaded ? "" : "active"}`}>
          Loading...
        </div>
        <iframe
          style={{ borderRadius: "1px" }}
          title="SPOTIFY"
          src={`https://open.spotify.com/embed/${spotifyUrl}?utm_source=generator`}
          width="100%"
          height="152"
          frameBorder="0"
          allowFullScreen=""
          allow="encrypted-media"
          loading="eager"
          onLoad={handleLoaded}
          className={`spotify__embed ${spotifyLoaded ? "active" : ""}`}
        ></iframe>
      </div>
    </div>
  );
};

export default Spotify;
