const ContentExternalLinks = (props) => {
  const { links } = props;

  if(links){
  return (
    <div className="content__section-wrapper bold">
        <h3 className="content__section-title">External links:</h3>
      {links.map((link, index) => {
          return (
            <a href={link.link} target="_blank" rel="noreferrer" key={index}>
                {link.title}
            </a>
          );
        })}
    </div>
  )} else {
    return;
  }
};

export default ContentExternalLinks;
