import { useEffect, useState } from "react";

const ConnectionToggle = (props) => {
  const { connectionType, visibleLines, setVisibleLines, visibleEntries, setVisibleEntries } = props;

  const toggleVisibleLines = () => {
    const newVisibleLines = { ...visibleLines };
    if (newVisibleLines[connectionType]) {
      newVisibleLines[connectionType] = false;
    } else {
      newVisibleLines.all = true;
      newVisibleLines[connectionType] = true;
    }
    setVisibleLines(newVisibleLines);
  };

  const [translatedConnectionType, setTransConnectionType] = useState("");

  useEffect(() => {
    switch (connectionType) {
      case "all":
        setTransConnectionType("All");
        break;
      case "track":
        setTransConnectionType("Tracks");
        break;
      case "ref":
        setTransConnectionType("References");
        break;
      case "parent":
        setTransConnectionType("Parent Releases");
        break;
      default:
        setTransConnectionType("All");
        break;
    }
  }, [connectionType]);

  return (
    <div className="key__entry-wrapper">
    <div className="key__entry-text connection">{translatedConnectionType}</div>
    <div className={`key__entry-line ${connectionType}`}></div>
    <div className="key__toggle-wrapper">
      <div className="key__toggle-green"></div>
      <button
        className={`key__toggle-button ${visibleLines[connectionType] ? "active" : ""}`}
        onClick={toggleVisibleLines}
      ></button>
    </div>
  </div>
  );
};

export default ConnectionToggle;
