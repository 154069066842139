import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../../../hooks/usePortableTextComponents";

const ContentInfo = (props) => {

    const {       
        content
    } = props;

  return (
    <div className="content__section-wrapper">
        <PortableText value={content} components={UnderlineLink} />
    </div>
  )
}

export default ContentInfo