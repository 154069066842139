import { useRef, useState } from "react";
import { motion } from "framer-motion";

import Entries from "../components/Entries/Entries";
import ConnectionsSelectedEntry from "../components/Connections/ConnectionsSelectedEntry";
import ConnectionsRefs from "../components/Connections/ConnectionsRefs";
import ConnectionsTracks from "../components/Connections/ConnectionsTracks";
import ConnectionsParents from "../components/Connections/ConnectionsParents";

import Header from "../components/Interface/Header";
import Key from "../components/Interface/Key";
import ListView from "../components/Interface/ListView";
import ContentWrapper from "../components/Content/ContentWrapper";
import Spotify from "../components/Interface/Spotify";

import MouseControls from "../components/Interface/MouseControls";
import Information from "../components/Interface/Information";
import Loading from "../components/Interface/Loading";
import Preloader from "../components/Interface/Preloader";

const Home = (props) => {
  const { siteData, isMobile } = props;

  const containerRef = useRef();

  const [entries, setEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState("");

  const [connections, setConnections] = useState([]);
  const [trackConnections, setTrackConnections] = useState([]);
  const [parentConnections, setParentConnections] = useState([]);

  const [keyIsActive, setKeyIsActive] = useState(false);
  const [listViewActive, setListViewActive] = useState(false);
  const [infoActive, setInfoActive] = useState(false);

  const [spotifyUrl, setSpotifyUrl] = useState("");
  const [spotifyIsActive, setSpotifyIsActive] = useState(false);

  const historyIndex = useRef(0);
  const historyIds = useRef([]);

  const [scale, setScale] = useState(1);

  const [visibleEntries, setVisibleEntries] = useState({
    all: true,
    album: true,
    collaboration: true,
    ep: true,
    filmScore: true,
    liveRecording: true,
    liveShow: true,
    miniAlbum: true,
    radioShow: true,
    ref: true,
    single: true,
    track: true,
    video: true,
  });

  const [visibleLines, setVisibleLines] = useState({
    all: true,
    ref: true,
    track: true,
    parent: true,
  });

  const isDragging = useRef(false);
  const [elmIsDragging, setElmIsDragging] = useState(false);

  const startX = useRef(window.innerWidth * 2.5);
  const startY = useRef(window.innerHeight * 2.5);
  const scrollLeft = useRef(window.innerWidth * 2.5);
  const scrollTop = useRef(window.innerHeight * 2.5);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.clientX;
    startY.current = e.clientY;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current || elmIsDragging) return;

    const deltaX = e.clientX - startX.current;
    const deltaY = e.clientY - startY.current;
    const container = containerRef.current;

    if (container) {
      container.scrollLeft = scrollLeft.current - deltaX;
      container.scrollTop = scrollTop.current - deltaY;
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    scrollLeft.current = containerRef.current.scrollLeft;
    scrollTop.current = containerRef.current.scrollTop;
  };

  const handleWheel = (e) => {
    const container = containerRef.current;

    if (container) {
      startX.current = containerRef.current.scrollLeft + window.innerWidth / 2;
      startY.current = containerRef.current.scrollTop + window.innerHeight / 2;
      scrollLeft.current = containerRef.current.scrollLeft;
      scrollTop.current = containerRef.current.scrollTop;
    }
  };

  if (siteData) {
    return (
      <>
        <motion.div
          key={"home-wrapper"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.4 } }}
          exit={{ opacity: 0, transition: { duration: 0.4 } }}
        >
          <Preloader
            {...{
              containerRef,
              isMobile,
            }}
          />
          <Header
            {...{
              keyIsActive,
              setKeyIsActive,
              listViewActive,
              setListViewActive,
              infoActive,
              setInfoActive,
            }}
          />
          <MouseControls
            {...{
              entries,
              selectedEntry,
              containerRef,
              scale,
              setScale,
              startX,
              startY,
              scrollLeft,
              scrollTop,
              visibleEntries,
              visibleLines,
            }}
          />
          <Key
            {...{
              visibleEntries,
              setVisibleEntries,
              visibleLines,
              setVisibleLines,
              keyIsActive,
              setKeyIsActive,
              spotifyIsActive,
            }}
          />
          <ListView
            {...{
              entries,
              selectedEntry,
              setSelectedEntry,
              listViewActive,
              setListViewActive,
              spotifyIsActive,
              visibleEntries,
              setVisibleEntries,
              isMobile,
              historyIds,
              historyIndex,
            }}
          />
          <Information
            {...{
              infoActive,
              setInfoActive,
              spotifyIsActive,
            }}
          />
          <ContentWrapper
            {...{
              entries,
              selectedEntry,
              setSelectedEntry,
              connections,
              trackConnections,
              parentConnections,
              spotifyIsActive,
              setSpotifyIsActive,
              spotifyUrl,
              setSpotifyUrl,
              historyIndex,
              historyIds,
              visibleEntries,
              setVisibleEntries,
            }}
          />
          <Spotify
            {...{
              spotifyIsActive,
              setSpotifyIsActive,
              spotifyUrl,
              setSpotifyUrl,
            }}
          />

          <main
            className="interface__container"
            ref={containerRef}
            onMouseDown={!isMobile ? handleMouseDown : null}
            onMouseMove={!isMobile ? handleMouseMove : null}
            onMouseUp={!isMobile ? handleMouseUp : null}
            onWheel={handleWheel}
            onMouseLeave={!isMobile ? handleMouseUp : null}
          >
            <div
              className="interface__outer-wrapper"
              style={{
                width: `${600 * scale}vw`,
                height: `${600 * scale}vh`,
              }}
            >
              <div
                className="interface__entries-wrapper"
                style={{
                  transform: `scale(${scale})`,
                }}
              >
                <Entries
                  {...{
                    siteData,
                    entries,
                    setEntries,
                    visibleEntries,
                    selectedEntry,
                    setSelectedEntry,
                    historyIndex,
                    historyIds,
                    connections,
                    trackConnections,
                    parentConnections,
                    elmIsDragging,
                    setElmIsDragging,
                    isMobile,
                    scale
                  }}
                />
              </div>

              <div
                className={`interface__connections-wrapper ${
                  selectedEntry === "" ? "" : "disabled"
                }`}
                style={{
                  transform: `scale(${scale})`,
                }}
              >
                <ConnectionsRefs
                  {...{
                    entries,
                    connections,
                    setConnections,
                    visibleEntries,
                    visibleLines,
                    containerRef,
                    scale
                  }}
                />
                <ConnectionsTracks
                  {...{
                    entries,
                    trackConnections,
                    setTrackConnections,
                    visibleEntries,
                    visibleLines,
                    containerRef,
                    scale
                  }}
                />
                <ConnectionsParents
                  {...{
                    entries,
                    parentConnections,
                    setParentConnections,
                    visibleEntries,
                    visibleLines,
                    containerRef,
                    scale
                  }}
                />
              </div>
              <div
                className={`interface__connections-wrapper ${
                  selectedEntry === "" ? "disabled" : ""
                }`}
                style={{
                  transform: `scale(${scale})`,
                }}
              >
                <ConnectionsSelectedEntry
                  {...{
                    entries,
                    connections,
                    trackConnections,
                    parentConnections,
                    selectedEntry,
                    visibleLines,
                    containerRef,
                    scale
                  }}
                />
              </div>
            </div>
          </main>
        </motion.div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Home;
