import { useEffect, useState } from "react";
import translateType from "../../../hooks/translateType";
import Icon from "../../Interface/Icon";

const TracksFromOutside = (props) => {
  const {
    entries,
    contentEntry,
    setSelectedEntry,
    historyIndex,
    historyIds,
    visibleEntries,
    setVisibleEntries,
  } = props;

  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const filteredEntries = entries.filter((entry) =>
      entry.tracks?.some((track) => track._id === contentEntry.id)
    );
    setFeatures(filteredEntries);
  }, [contentEntry, entries]);

  if (features.length > 0) {
    return (
      <>
        <div className="content__section-wrapper bold">
          <div className="content__connections-wrapper">
            <h3 className="content__section-title">Featured on:</h3>
            {features.map((feature, index) => {
              const handleClick = () => {
                setSelectedEntry(feature.id);
                historyIds.current.push(feature.id);
                historyIndex.current = historyIds.current.length;
                if (!visibleEntries[feature.type]) {
                  const newVisibleEntries = { ...visibleEntries };
                  newVisibleEntries[feature.type] = true;
                  setVisibleEntries(newVisibleEntries);
                }
              };
              return (
                <button
                  className="content__connection-ref-wrapper"
                  key={index}
                  onClick={handleClick}
                >
                  <div className="content__connection-icon">
                    <Icon type={feature.type} />
                  </div>
                  <div className="content__connection-text">
                    <div>{translateType(feature.type)}:</div>
                    <h3>{feature.title}</h3>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </>
    );
  } else {
    return;
  }
};

export default TracksFromOutside;
