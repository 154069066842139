import React, { useEffect } from 'react'

const ListViewSort = (props) => {
    const {
        sortMethod,
        setSortMethod,
        type
    } = props;

    const handleSetAsc = () => {
        setSortMethod({
            type: type,
            direction: 'asc'
        })
    }
    const handleSetDesc = () => {
        setSortMethod({
            type: type,
            direction: 'desc'
        })
    }
  return (
    <button className={`list-view__sort-button-wrapper 
    ${sortMethod.type === type && sortMethod.direction === 'asc'?'asc':''}
    ${sortMethod.type === type && sortMethod.direction === 'desc'?'desc':''}
    ${sortMethod.type === type?'active':''}`}
    onClick={sortMethod.direction === 'asc'?handleSetDesc:handleSetAsc}>
        {type}
    </button>
  )
}

export default ListViewSort