import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "./views/Home";

import sanityClient from "./client";
import "./styles/global-styles.scss";

function App() {
  const location = useLocation();

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 750) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [siteData, setSiteData] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[]{
        ...,
        title,
        _type,
        _id,
        spotifyLink,
        references[]{
          ...,
          title,
          refRef->{
            title,
            _id,
            _type
          }
        },
        tracks[]->{
          ...,
          title,
          _id,
        },
        "parentRelease" : parentRelease->{
          ...,
          _id,
          _type,
          title,
        },
        externalLinks[]
       }`
      )
      .then((data) => {
        setSiteData(data);
      })
      .catch();
  }, []);

  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route element={<Home key={"home"} siteData={siteData} isMobile={isMobile}/>} path="/" />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
